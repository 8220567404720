import "./styles.scss"

import React from "react"

import ShapeBox from "components/ShapeBox"

import { convenienceData } from "data"

const Convenience = () => {
  return (
    <section className="investment-convenience">
      <div className="container-fluid">
        <h3>Udogodnienia</h3>
        <div className="row">
          {convenienceData?.map((item, index) => (
            <div
              className={`col-md-4 ${index === 3 ? "offset-md-2" : ""}`}
              key={index}
            >
              <ShapeBox
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Convenience
